class BusinessLocals {
    constructor(el) {
        if (!this.set(el)) return;
        this.init();
    }

    set(el) {
        if (!el) return false;

        this.root = el;
        this.tabEls = this.root.querySelectorAll('[data-business-locals-av]');
        this.listEls = this.root.querySelectorAll('[data-business-locals-inv]');
        this.detailEls = this.root.querySelectorAll(
            '[data-contact-data-tabs-detail]'
        );

        return true;
    }

    init() {
        this.setTabs();
    }

    setTabs() {
        if (!this.tabEls) return;

        this.onTab = this.onTabClick.bind(this);
        this.tabEls.forEach(item => item.addEventListener('click', this.onTab));
    }

    onTabClick(event) {
        const { currentTarget } = event;
        const index = parseInt(
            currentTarget.getAttribute('data-business-locals-av')
        );
        const listEl = this.root.querySelector(
            `[data-business-locals-inv="${index}"]`
        );

        this.resetItems(this.tabEls);
        this.resetItems(this.listEls);

        currentTarget.classList.add('-active');
        if (listEl) listEl.classList.add('-active');
    }

    resetItems(items) {
        items.forEach(item => item.classList.remove('-active'));
    }
}

function initBusinessLocals() {
    const sections = document.querySelectorAll('[data-business-locals]');
    if (!sections) return;

    sections.forEach(item => new BusinessLocals(item));
}

if (
    document.readyState === 'complete' ||
    (document.readyState !== 'loading' && !document.documentElement.doScroll)
) {
    initBusinessLocals();
} else {
    document.addEventListener('DOMContentLoaded', initBusinessLocals);
}
